import { useSelector, useDispatch } from 'react-redux';
import { setConfirmedByUser, selectPristine, selectRequiresConfirmation } from '../app/reducers/paymentMethodChange'

export default function PaymentMethodConfirmation(){
  const dispatch = useDispatch();
  const requiresConfirmation = useSelector(selectRequiresConfirmation);
  const pristine = useSelector(selectPristine);
  const selectResult = (result) => { dispatch(setConfirmedByUser(result)) }
  return (
    <div className={"loading-overlay "+(pristine ? 'pristine' : '')+(requiresConfirmation ? ' is-loading' : ' is-not-loading')}>
      {!pristine && <div className={"loading-detail"}>
        <div className={"modal-title"}>Cancelar Método</div>
        <div className={"modal-message"}>¿Esta seguro que desea cambiar de método de pago?</div>
        <div className={"modal-buttons"}>
          <button className={"modal-button"} onClick={()=>selectResult("NO")}>No</button>
          <button className={"modal-button modal-button-highlighted"} onClick={()=>selectResult("OK")}>Si</button>
        </div>
      </div>}
    </div>
  );
}