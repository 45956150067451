import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    loading: false
  },
  reducers: {
    setLoadingState: (state) => { state.loading=true },
    loadToken: (state, action) => {
      console.log({loadToken:action.payload})
      state.token = action.payload;
      state.loading = false;
    }
  },
  selectors: {
    selectToken: (state) => state.token,
    selectLoading: (state) => state.loading,
    selectError: (state) => state.error
  }
});

export const { setLoadingState, loadToken } = authSlice.actions;

export const { selectToken, selectLoading, selectError } = authSlice.selectors;

export default authSlice.reducer;
