import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUrlAssets } from '../constants'
import { selectFields, setFieldValue, useSelectFieldByName, useSelectFieldValueByName } from '../app/reducers/paymentMethod'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import PinField from "react-pin-field";
import Select from "react-dropdown-select";

export const TEXT_INPUT_FIELD = "text-field";
export const SELECT_FIELD = "select-field";
export const CARD_PREVIEW = "card-preview";
export const CARD_INPUT_FIELD = "card-field";
export const CARD_CVV_INPUT_FIELD = "card-cvv-field";
export const CARD_EXPIRATION_INPUT_FIELD = "card-expiration-field";
export const PHONE_INPUT_FIELD = "phone-field";
export const VERIFICATION_CODE_INPUT_FIELD = "verification-code-field";
export const MAIN_IMAGE_FIELD = "main-image-field";
export const PARAGRAPH_FIELD = "paragraph-field";
export const ICONS_BELT_FIELD = "icons-belt-field";
export const BIG_IMAGE_FIELD = "big-image-field";
export const CIP_CODE_FIELD = "cip-code-field";
export const BUTTON_BAR_FIELD = "button-bar-field";
export const TEXT_LIST_FIELD = "text-list-field";
export const DROPDOWN_INSTALLMENTS_FIELD = "dropdown-installments-field";

export default function Field({
  type,
  className = "",
  label,
  placeholder,
  value,
  name,
  id,
  attributes,
  options,
  onUpdateValue,
  url,
  weight,
  content,
  icons,
  random_key,
  show_when,
  list,
  size
}) {
  const allFields = useSelector(selectFields);
  const field=useSelectFieldByName(name);
  const fieldValue=useSelectFieldValueByName(name);
  const dispatch = useDispatch();
  const hasToShow = (fields) => fields.findIndex((f)=>{
    if("name" in f){
      if("value" in f){
        if(show_when!=null && show_when!=undefined){
          return f.name==show_when.field_name && f.value==show_when.value;
        }
      }
    }
    return false;
  })>=0;
  const getFieldValue = (fields, field_name)=>{
    let val="";
    let valFound=false;
    for(let k in fields){
      if(fields[k]["name"]==field_name && fields[k]["value"]!=undefined){
        val=String(fields[k]["value"]);
        valFound=true;
        break;
      }
    }
    if(val==""){
      valFound=false;
    }
    if(valFound){
      if(field_name=="field_expiration"){
        if(val.length>2){
          val=val.substring(0, 2) + "/" + val.substring(2);
        }
      }
    }else{
      if(field_name=="field_expiration"){
        val="MM/AA";
      }else if(field_name=="field_cardholder_name"){
        val="NOMBRES Y";
      }else if(field_name=="field_cardholder_lastname"){
        val="APELLIDOS";
      }
    }
    return val;
  };
  const getFieldMeta = (fields, field_name)=>{
    let meta={brand:"NA",installments:{list:[]}};
    for(let k in fields){
      if(fields[k]["name"]==field_name && fields[k]["meta"]!=undefined){
        meta=fields[k]["meta"];
        break;
      }
    }
    if(meta.brand==undefined){
      meta.brand="NA";
    }
    if(meta.installments==undefined){
      meta.installments={list:[]};
    }
    return meta;
  };
  const onChangeValue=(value)=>{
    if (type == DROPDOWN_INSTALLMENTS_FIELD) {
      let value_selected = "installments" in value ? value["installments"] : "0";
      dispatch(setFieldValue({name, value:value_selected, meta:value}));
      return;
    }
    if (attributes && "max_length" in attributes && value) {
      value = value.substr(0, attributes.max_length);
    }
    let withValue = (value != "" && value != null);
    if (withValue && type == CARD_EXPIRATION_INPUT_FIELD && value.length > 2) {
      value = value.replace("/", "");
    }
    dispatch(setFieldValue({name, value}));
  };
  let [hasFocus, setHasFocus] = useState(false);
  name = !name ? "field" : name;
  id = !id ? `${name}_${random_key}` : id;
  let getInputType = () => {
    switch (type) {
      case TEXT_INPUT_FIELD:
      case CARD_EXPIRATION_INPUT_FIELD:
        return "text";
      case CARD_CVV_INPUT_FIELD:
        return "text";
      case CARD_INPUT_FIELD:
      case PHONE_INPUT_FIELD:
      case VERIFICATION_CODE_INPUT_FIELD:
        return "number";
      default:
        return "text";
    }
  }
  let input_type = getInputType();
  let onFocus = (e) => {
    setHasFocus(true);
  }
  let onBlur = (e) => {
    setHasFocus(false);
  }
  const makeHasValue = () => {
    let value = fieldValue;
    return (value != "" && value != null);
  }
  const makeInputValue = () => {
    let value = fieldValue;
    if(!hasValue)
      return "";
    if (hasValue && type == CARD_EXPIRATION_INPUT_FIELD && value.length > 2) {
      value = value.replace("/", "");
      value = value.substr(0, 2) + "/" + value.substr(2, value.length);
    }
    if (hasValue && type == DROPDOWN_INSTALLMENTS_FIELD && value.length > 0) {
      let list = getFieldMeta(allFields,"field_card")["installments"]["list"];
      let element = list.find(f => f.installments==value);
      value = element!=undefined ? [element] : [];
    }
    return value;
  };
  const hasValue = makeHasValue();
  const inputValue = makeInputValue();
  const makeClassName = () => {
    let cn = "flex-input";
    cn += " flex-" + type;
    cn += className ? " " + className : "";
    cn += size ? " "+size : " md";
    cn += hasFocus ? " has-focus" : "";
    cn += hasValue ? " has-value" : "";
    if(field != undefined && "is_valid" in field){
      cn += field.is_valid ? " valid": " invalid";
    }if(field != undefined && "name" in field){
      cn += " " + field.name;
    }
    return cn;
  }
  const fieldClassName = makeClassName();
  const makeStyle = () => {
    let style={};
    if(weight) style['fontWeight']=weight;
    return style;
  }
  const installmentsContentRenderer = ({ props, state }) => (
    state.values.length>0 && <div className={"installment-wrap"} style={{ cursor: 'pointer' }}>
      <div className={"installment-element installment-label"}>{state.values[0].label}</div>
      <div className={"installment-element installment-detail"}>
        {state.values[0].installments!="0" && <div className={"installment-description"+(state.values[0].interest?"":" installment-with-interest")}>{state.values[0].interest?"Con Interés":"Sin Interés"}</div>}
      </div>
    </div>
  );
  const installmentsItemRenderer = ({ item, methods }) => (
    <div className={"installment-item-wrap"} style={{ cursor: 'pointer' }} onClick={() => methods.addItem(item)}>
      <div className={"installment-element installment-label"}>{item.label}</div>
      <div className={"installment-element installment-detail"}>
        {item.installments!="0" && <div className={"installment-description"+(item.interest?"":" installment-with-interest")}>{item.interest?"Con Interés":"Sin Interés"}</div>}
      </div>
    </div>
  );
  switch (type) {
    case SELECT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <select
            id={id} 
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}>
            {options.map((data,pos)=>(<option key={pos} value={data.value}>{data.label}</option>))}
          </select>
        </div>
      );
    case CARD_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <input
            id={id} 
            type={input_type}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} />
          <div className={"error-msg"}>{field.error}</div>
        </div>
      );
    case CARD_CVV_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <input
            id={id} 
            type={input_type} 
            placeholder={placeholder}
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} />
          <div className={"error-msg"}>{field.error}</div>
        </div>
      );
    case CARD_EXPIRATION_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <input
            id={id} 
            type={input_type} 
            placeholder={placeholder}
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} />
          <div className={"error-msg"}>{field.error}</div>
        </div>
      );
    case TEXT_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <input
            id={id} 
            type={input_type} 
            placeholder={placeholder}
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} />
          <div className={"error-msg"}>{field.error}</div>
        </div>
      );
    case DROPDOWN_INSTALLMENTS_FIELD:
      return (
        (getFieldMeta(allFields,"field_card")["installments"]["list"].length>0) &&
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <Select
            values={inputValue}
            options={getFieldMeta(allFields,"field_card")["installments"]["list"]}
            labelField="label"
            valueField="installments"
            dropdownHeight="160px"
            contentRenderer={installmentsContentRenderer}
            itemRenderer={installmentsItemRenderer}
            onChange={(values) => onChangeValue(values[0])}
          />
        </div>
      );
    case PHONE_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <input
            id={id} 
            type={input_type} 
            placeholder={placeholder}
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} />
          <div className={"error-msg"}>{field.error}</div>
        </div>
      );
    case VERIFICATION_CODE_INPUT_FIELD:
      return (
        <div className={fieldClassName}>
          {label && <label htmlFor={id}>{label}</label>}
          <div className="pin-field-container">
            <PinField 
              className="pin-field" 
              length={6} 
              validate="0123456789"
              onChange={(code)=>onChangeValue(code)}
              inputMode="numeric"  />
          </div>
          {false && <input
            id={id} 
            type={input_type} 
            value={inputValue}
            onChange={(e)=>onChangeValue(e.target.value)}
            onFocus={onFocus}
            onBlur={onBlur} 
            pattern="[0-9]{6}"
            maxLength={attributes.max_length} />}
        </div>
      );
    case MAIN_IMAGE_FIELD:
      return (
        <div className={fieldClassName}>
          <img className={"logo"} src={url} />
        </div>
      );
    case BIG_IMAGE_FIELD:
      return (
        <div className={fieldClassName}>
          <img src={url} />
        </div>
      );
    case PARAGRAPH_FIELD:
      return (
        <div className={fieldClassName} style={makeStyle()}>
          {content}
        </div>
      );
    case ICONS_BELT_FIELD:
      return (
        <div className={fieldClassName} >
          {icons.map((value,pos) => <img key={pos} className={"icon-in-belt"} src={value} />)}
        </div>
      );
    case CIP_CODE_FIELD:
      return (
        <div className={fieldClassName}>
          <div className={"label"}>{label}</div>
          <div className={"value"}>{content}<CopyToClipboard text={content}><img src={attributes.copy_icon} /></CopyToClipboard></div>
        </div>
      );
    case BUTTON_BAR_FIELD:
      return (
        <div className={fieldClassName}>
          {options.map((data,pos)=>(<div key={pos} className={"button "+(value==data.value?" selected":"")} onClick={()=>onChangeValue(data.value)}>{data.label}</div>))}
        </div>
      );
    case TEXT_LIST_FIELD:
      return (
        <div className={fieldClassName + (hasToShow(allFields)?" show":" hide")}>
          {list.map((data,pos)=>(<div key={pos}>{(pos+1)+"."} {data}</div>))}
        </div>
      );
    case CARD_PREVIEW:
      return (
        <div className={fieldClassName+" card-preview-brand-"+getFieldMeta(allFields,"field_card")["brand"]}>
          <div className={"card-preview-row right"}>
            <div className={"card-preview-image-brand"}/>
          </div>
          <div className={"card-preview-row"}>
            <img src={getUrlAssets()+"/card_chip.png"} />
          </div>
          <div className={"card-preview-row"}>
            <div className={"card-preview-card"}>{getFieldValue(allFields,"field_card")}</div>
          </div>
          <div className={"card-preview-row"}>
            <div className={"card-preview-name"}>{getFieldValue(allFields,"field_cardholder_name")+" "+getFieldValue(allFields,"field_cardholder_lastname")}</div>
            <div className={"card-preview-expiry-date"}>{getFieldValue(allFields,"field_expiration")}</div>
          </div>
        </div>
      );
    default:
      return (<p>{label}</p>);
  }
}
