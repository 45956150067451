import { useSelector, useDispatch } from 'react-redux'
import { selectFields } from '../app/reducers/paymentMethod'
import Field from "../components/Field";
export default function PaymentForm(){
  const fields = useSelector(selectFields);
  return (
    <>
      {fields.map((value,pos) => <Field key={value.random_key} {...value} />)}
    </>
  )
}
