import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { authApi } from './services/auth';
import { sessionApi } from './services/session';
import { operationApi } from './services/operation';
import { paymentMethodApi } from './services/paymentMethod';
import { validationApi } from './services/validation';
import { authenticationApi } from './services/authentication';
import { authMiddleware } from './middlewares/auth';
import { sessionMiddleware } from './middlewares/session';
import { operationMiddleware } from './middlewares/operation';
import { methodsListMiddleware } from './middlewares/methodsList';
import { paymentMethodMiddleware } from './middlewares/paymentMethod';
import { loopConsultPaymentMiddleware } from './middlewares/loopConsultPayment';
import { paymentMethodChangeMiddleware } from './middlewares/paymentMethodChange';
import { authenticationMiddleware } from './middlewares/authentication';
import { authSlice } from './reducers/auth';
import { sessionSlice } from './reducers/session';
import { operationSlice } from './reducers/operation';
import { methodsListSlice } from './reducers/methodsList';
import { paymentMethodSlice } from './reducers/paymentMethod';
import { paymentMethodChangeSlice } from './reducers/paymentMethodChange';
import { loopConsultPaymentSlice } from './reducers/loopConsultPayment';
import { flexConfigurationSlice } from './reducers/flexConfiguration';
import { successAnimationSlice } from './reducers/successAnimation';
import { delayClockSlice } from './reducers/delayClock';
import { integrationsSlice } from './reducers/integrations';
import { authenticationSlice } from './reducers/authentication';

export const createStore = () => {
    let store = configureStore({
        reducer: {
            [authSlice.reducerPath]: authSlice.reducer,
            [sessionSlice.reducerPath]: sessionSlice.reducer,
            [flexConfigurationSlice.reducerPath]: flexConfigurationSlice.reducer,
            [operationSlice.reducerPath]: operationSlice.reducer,
            [methodsListSlice.reducerPath]: methodsListSlice.reducer,
            [paymentMethodSlice.reducerPath]: paymentMethodSlice.reducer,
            [paymentMethodChangeSlice.reducerPath]: paymentMethodChangeSlice.reducer,
            [successAnimationSlice.reducerPath]: successAnimationSlice.reducer,
            [delayClockSlice.reducerPath]: delayClockSlice.reducer,
            [integrationsSlice.reducerPath]: integrationsSlice.reducer,
            [loopConsultPaymentSlice.reducerPath]: loopConsultPaymentSlice.reducer,
            [authenticationSlice.reducerPath]: authenticationSlice.reducer,
            [authApi.reducerPath]: authApi.reducer,
            [sessionApi.reducerPath]: sessionApi.reducer,
            [operationApi.reducerPath]: operationApi.reducer,
            [paymentMethodApi.reducerPath]: paymentMethodApi.reducer,
            [validationApi.reducerPath]: validationApi.reducer,
            [authenticationApi.reducerPath]: authenticationApi.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
            .concat(authApi.middleware)
            .concat(sessionApi.middleware)
            .concat(operationApi.middleware)
            .concat(paymentMethodApi.middleware)
            .concat(validationApi.middleware)
            .concat(authenticationApi.middleware)
            .concat(authMiddleware.middleware)
            .concat(sessionMiddleware.middleware)
            .concat(operationMiddleware.middleware)
            .concat(methodsListMiddleware.middleware)
            .concat(paymentMethodMiddleware.middleware)
            .concat(loopConsultPaymentMiddleware.middleware)
            .concat(paymentMethodChangeMiddleware.middleware)
            .concat(authenticationMiddleware.middleware)
    });
    setupListeners(store.dispatch);
    return store;
};