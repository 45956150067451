import { createListenerMiddleware } from '@reduxjs/toolkit';
import { operationApi } from '../services/operation';
import { setLoadingState, loadOperation } from '../reducers/operation';
import { selectDisplaySettings } from '../reducers/flexConfiguration';
import { loadList } from '../reducers/methodsList';

export const operationMiddleware = createListenerMiddleware();

operationMiddleware.startListening({
    matcher: operationApi.endpoints.createOperation.matchPending,
    effect: (action, listenerApi) => {
      console.info({onCreateOperationPending: action});
      listenerApi.dispatch(setLoadingState());
    }
});

operationMiddleware.startListening({
    matcher: operationApi.endpoints.createOperation.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onCreateOperationFulfilled: action});
      listenerApi.dispatch(loadOperation(action.payload));
      let displaySettings = selectDisplaySettings(listenerApi.getState());
      console.info({displaySettings});
      let methods=action.payload.payment_methods;
      console.info({methods});
      if(displaySettings!=null && displaySettings!=undefined){
        if(Array.isArray(displaySettings.methods)){
          if(displaySettings.methods.length>0){
            let arrMethods=[];
            for(var i in displaySettings.methods){
              for(var j in methods){
                if(String(displaySettings.methods[i]).toLowerCase()==String(methods[j]['code']).toLowerCase()){
                  arrMethods.push(methods[j]);
                  break;
                }
              }
                
            }
            if(arrMethods.length>0){
              methods=arrMethods;
            }
          }
        }
      }
      console.info({methodsAfter:methods});
      listenerApi.dispatch(loadList(methods));
    }
});