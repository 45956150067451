import { createSlice, createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux'

export const paymentMethodSlice = createSlice({
  name: 'paymentMethod',
  initialState: {
    id: null,
    loading: false,
    loadingTransaction: false,
  },
  reducers: {
    setLoadingState: (state, action) => { state.loading=action.payload },
    setLoadingTransactionState: (state, action) => { state.loadingTransaction=action.payload },
    setMockResponse: (state, action) => { state.mockResponse=action.payload },
    loadPaymentMethod: (state, action) => {
      let newState = {...action.payload, loading: false, firstView: true, mockResponse: "procesadora"};
      return newState;
    },
    setFields: (state, action) => {
      state.fields = action.payload;
      state.firstView = false;
    },
    setFormValid: (state, action) => { state.form_valid=action.payload },
    setNextAction: (state, action) => { state.nextAction=action.payload },
    setPaymentBtnText: (state, action) => { state.payment_btn_text=action.payload },
    setFieldValue: (state, action) => {
      let name=action.payload.name;
      let value=action.payload.value;
      if("meta" in action.payload){
        let meta=JSON.parse(JSON.stringify(action.payload.meta));
        state.fields = state.fields.map(
          (e)=>e.name==name?{...e, value, timestamp:Date.now(), meta}:e
        );
      }else{
        state.fields = state.fields.map(
          (e)=>e.name==name?{...e, value, timestamp:Date.now()}:e
        );
      }
      
    },
    setFieldIsValid: (state, action) => {
      let name=action.payload.name;
      let timestamp=action.payload.timestamp;
      let isValid=action.payload.is_valid;
      let error=action.payload.error;
      let meta=action.payload.meta!=undefined?JSON.parse(JSON.stringify(action.payload.meta)):{};
      let field = state.fields.find(f => f.name!=undefined && f.name===name);
      if(field != undefined){
        if(field.previousTimestamp!=undefined){
          if(field.previousTimestamp<timestamp){
            state.fields = state.fields.map(
              (e)=>e.name==name?{...e, is_valid:isValid, error, previousTimestamp:timestamp, meta}:e
            );
          }
        }else{
          state.fields = state.fields.map(
            (e)=>e.name==name?{...e, is_valid:isValid, error, previousTimestamp:timestamp, meta}:e
          );
        }
      }
    }
  },
  selectors: {
    selectId: (state) => state.id,
    selectLoading: (state) => state.loading,
    selectLoadingTransaction: (state) => state.loadingTransaction,
    selectName: (state) => state.name,
    selectFields: (state) => state.fields,
    selectPaymentBtnText: (state) => state.payment_btn_text,
    selectError: (state) => state.error,
    selectFormValid: (state) => state.form_valid,
    selectMockResponse: (state) => state.mockResponse,
    selectNextAction: (state) => state.nextAction,
    selectFirstView: (state) => state.firstView,
    selectFieldByName: createSelector(
      [(state)=>state.fields, (state, name)=>name],
      (fields, name)=>fields.find(f => f.name!=undefined && f.name===name)
    ),
    selectFieldValueByName: createSelector(
      [(state)=>state.fields, (state, name)=>name],
      (fields, name)=>{
        let field = fields.find(f => f.name!=undefined && f.name===name);
        return (field != undefined)? field.value : undefined;
      }
    )
  }
});

export const { setLoadingState, setLoadingTransactionState, loadPaymentMethod, setMockResponse, setFields, setNextAction, setPaymentBtnText, setFieldValue, setFieldIsValid, setFormValid } = paymentMethodSlice.actions;

export const { selectId, selectLoading, selectLoadingTransaction, selectName, selectFields, selectPaymentBtnText, selectError, selectFormValid, selectMockResponse, selectNextAction, selectFirstView, selectFieldByName, selectFieldValueByName } = paymentMethodSlice.selectors;

export const createParametricSelectorHook = selector => {
  return (...args) => {
    return useSelector(state => selector(state, ...args))
  }
}

export const useSelectFieldByName = createParametricSelectorHook(selectFieldByName);

export const useSelectFieldValueByName = createParametricSelectorHook(selectFieldValueByName);

export default paymentMethodSlice.reducer;
