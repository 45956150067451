import { useDispatch, useSelector } from 'react-redux'
import PaymentForm from "./PaymentForm";
import DelayClock from "../components/DelayClock";
import ModalIframe from "../components/ModalIframe";
import Modal from 'react-modal';
import { useState, useEffect } from 'react';
import { getUrlAssets } from '../constants'
import IframeResizer from 'iframe-resizer-react'
import { selectPaymentBtnText, selectMockResponse, selectFirstView, selectFormValid, paymentMethodSlice, selectLoading as selectPaymentMethodLoading, selectNextAction } from '../app/reducers/paymentMethod'
import { selectId as selectOperationId, operationSlice } from '../app/reducers/operation'
import { selectPristine as delayClockPristine, selectIsTicking, setIsTicking, selectDelay, selectTimeLeft, setTimeLeft } from '../app/reducers/delayClock'
import { useExecutePaymentMethodMutation } from '../app/services/paymentMethod'
import { selectList } from '../app/reducers/methodsList'
import { selectIntegrations } from '../app/reducers/integrations'
import { selectChallengeURL, selectChallengeInProgress } from '../app/reducers/authentication';

export default function PaymentMethod({ pos, method, chooseMethod, methodChoosed, responseCallback, trackingCallback, onErrorCallback }) {
    const dispatch = useDispatch();
    const operation = useSelector(operationSlice.selectSlice);
    const operationId = useSelector(selectOperationId);
    const paymentMethod = useSelector(paymentMethodSlice.selectSlice);
    const mockResponse = useSelector(selectMockResponse);
    const nextAction = useSelector(selectNextAction);
    const paymentMethodLoading = useSelector(selectPaymentMethodLoading);
    const [executePaymentMethod] = useExecutePaymentMethodMutation();
    const paymentBtnText = useSelector(selectPaymentBtnText);
    const isFirstView = useSelector(selectFirstView);
    const isFormValid = useSelector(selectFormValid);
    const clockPristine = useSelector(delayClockPristine);
    const delay = useSelector(selectDelay);
    const isTicking = useSelector(selectIsTicking);
    const timeLeft = useSelector(selectTimeLeft);
    const onStartOfDelay = () => { dispatch(setIsTicking(true)) };
    const onDelayTick = (count) => { dispatch(setTimeLeft(count)) };
    const onEndOfDelay = () => { dispatch(setIsTicking(false)); onSubmit() };
    const methodsList = useSelector(selectList);
    const integrations = useSelector(selectIntegrations);
    const challengeURL = useSelector(selectChallengeURL);
    const challengeInProgress = useSelector(selectChallengeInProgress);
    
    function buildWrapperClassName() {
        return "payment-method-wrapper " + method.id + " " + (methodChoosed ? " selected" : "")
    }

    function buildChooseBtnClassName() {
        return "payment-choose-btn choose-btn-" + (pos + 1) + (methodChoosed ? " selected" : "")
    }
    
    function getCurrencySymbol(){
      let code=operation.operation.payment_details.currency;
      switch(code){
        case "604": return "S/";
        case "840": return "$";
        default: return "";
      }
    }

    function onSubmit() {
      if(paymentMethodLoading){
        console.info(`Cant execute because paymentMethodLoading`);
        return;
      }
      if(isFirstView && !isFormValid){
        console.info(`Cant execute ${method.id}, invalid Form`);
        return;
      }
      if(nextAction!=undefined){
        if(nextAction.type=="open_popup"){
          console.info(`Cant execute, there is a popup`);
          return;
        }
        if(nextAction.type=="open_iframe_modal"){
          console.info(`Cant execute, there is a modal`);
          return;
        }
        if(nextAction.type=="execute_response_callback"){
          console.info(`Llamando al responseCallback`);
          let responseData=JSON.parse(JSON.stringify(nextAction.data));
          if("url" in responseData)
            delete responseData.url;
          if("image" in responseData)
            delete responseData.image;
          responseCallback(responseData);
          // responseCallback(nextAction.secured_data, nextAction.signature);
          return;
        }
        if(nextAction.type=="reset_payment_method"){
          chooseMethod(method, operation);
          return;
        }
      }
      console.info(`Executing ${method.id} for operation ${operationId}`);
      executePaymentMethod({
        operation,
        paymentMethod,
        integrations
      });
    }
    return (
        <div className={buildWrapperClassName()}>
        
        {methodChoosed && nextAction!=undefined && nextAction.type=="open_iframe_modal" && <ModalIframe url={nextAction.data.url} />}
        {methodChoosed && challengeInProgress && <ModalIframe url={challengeURL} />}
        
      {methodsList.length>1 && <div className={buildChooseBtnClassName()} onClick={() => chooseMethod(method, operation)}>
        <img src={method.small_icon} className={"sm-nav-payment-method-icon"} /><span className={"lbl-payment-method-name"}>{method.name}</span>
      </div>}
      {methodChoosed &&
      <div className={"payment-method-form-wrapper"+((methodsList.length>1)?"":" payment-method-form-single")}>
        <div className={"payment-method-form-content"}>
          <PaymentForm />
        </div>
        <div className={"payment-method-payme-section"}>
          <img src={getUrlAssets()+ "/logo_payme.png"} />
        </div>
        <div className={"payment-method-amount-detail"}>
          <div className={"amount-label"}>Pago total</div>
          <div className={"amount-value"}><span className={"currency"}>{getCurrencySymbol()}</span><span className={"integer-part"}>{String(operation.operation.payment_details.amount).slice(0,-2)}</span><span className={"decimal-part"}>.{String(operation.operation.payment_details.amount).slice(-2)}</span></div>
        </div>
        <div className={"payment-method-submit-btn"+((isFirstView && !isFormValid)?" disabled":"")}>
          <DelayClock onEndOfDelay={onEndOfDelay} delay={delay} onStartOfDelay={onStartOfDelay} onDelayTick={onDelayTick} />
          <button type="button" onClick={e=>{onSubmit()}}>{isTicking ? paymentBtnText + " en " + timeLeft + " seg." : paymentBtnText}</button>
        </div>
      </div>}
    </div>
    )
}
