import { createSlice } from '@reduxjs/toolkit';

export const successAnimationSlice = createSlice({
  name: 'successAnimation',
  initialState: {
    pristine: true,
    isPlaying: false
  },
  reducers: {
    setIsPlaying: (state, action) => {
      state.isPlaying=action.payload;
      state.pristine=false;
    },
  },
  selectors: {
    selectPristine: (state) => state.pristine,
    selectIsPlaying: (state) => state.isPlaying,
  }
});

export const { setIsPlaying } = successAnimationSlice.actions;

export const { selectPristine, selectIsPlaying } = successAnimationSlice.selectors;

export default successAnimationSlice.reducer;