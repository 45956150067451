import { createListenerMiddleware } from '@reduxjs/toolkit';
import { paymentMethodApi } from '../services/paymentMethod';
import { setOperationConsult, setActive, selectOperationConsult } from '../reducers/loopConsultPayment';

export const loopConsultPaymentMiddleware = createListenerMiddleware();

loopConsultPaymentMiddleware.startListening({
    actionCreator: setOperationConsult,
    effect: (action, listenerApi) => {
      console.info({onSetOperationConsult: action});
      listenerApi.dispatch(setActive(true));
    }
});

loopConsultPaymentMiddleware.startListening({
    actionCreator: setActive,
    effect: (action, listenerApi) => {
      console.info({onSetActive: action});
      if(action.payload){
        let operationConsult = selectOperationConsult(listenerApi.getState());
        let operation = operationConsult.operation;
        let paymentMethod = operationConsult.paymentMethod;
        listenerApi.dispatch(paymentMethodApi.endpoints.consultPaymentStatus.initiate({operation, paymentMethod}));
      }
    }
});