import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { paymentMethodApi } from '../services/paymentMethod';
import { validationApi } from '../services/validation';
import { setLoadingState, setLoadingTransactionState, loadPaymentMethod, setFields, setNextAction, setPaymentBtnText, setFieldValue, setFieldIsValid, setFormValid, selectFields, selectFieldByName, paymentMethodSlice} from '../reducers/paymentMethod';
import { operationSlice } from '../reducers/operation';
import { setOperationConsult, setActive, selectOperationConsult, selectActive } from '../reducers/loopConsultPayment';
import { selectOperationChange, setOperationChange, setRequiresConfirmation, setConfirmedByUser } from '../reducers/paymentMethodChange';
import { setIsPlaying } from '../reducers/successAnimation';
import { setDelay as setDelayTime } from '../reducers/delayClock';
import { setChargesResponse } from '../reducers/authentication';

export const paymentMethodMiddleware = createListenerMiddleware();

paymentMethodMiddleware.startListening({
    matcher: isAnyOf(
      paymentMethodApi.endpoints.setPaymentMethod.matchPending,
      paymentMethodApi.endpoints.setPaymentMethodWithConfirmation.matchPending
      ),
    effect: (action, listenerApi) => {
      console.info({onSetPaymentMethodPending: action});
      listenerApi.dispatch(setLoadingState(true));
    }
});

paymentMethodMiddleware.startListening({
    matcher: paymentMethodApi.endpoints.executePaymentMethod.matchPending,
    effect: (action, listenerApi) => {
      console.info({onExecutePaymentMethodPending: action});
      listenerApi.dispatch(setLoadingTransactionState(true));
    }
});

paymentMethodMiddleware.startListening({
    matcher: isAnyOf(
      paymentMethodApi.endpoints.setPaymentMethod.matchFulfilled,
      paymentMethodApi.endpoints.setPaymentMethodWithConfirmation.matchFulfilled
      ),
    effect: (action, listenerApi) => {
      console.info({onSetPaymentMethodFulfilled: action});
      listenerApi.dispatch(loadPaymentMethod(action.payload));
    }
});

paymentMethodMiddleware.startListening({
    matcher: paymentMethodApi.endpoints.setPaymentMethod.matchRejected,
    effect: (action, listenerApi) => {
      console.info({onSetPaymentMethodRejected: action});
      listenerApi.dispatch(setLoadingState(false));
      try{
        console.info("check if require confirmation");
        if(action.payload.data.error=="requires_confirmation"){
          console.info("requires_confirmation");
          listenerApi.dispatch(setRequiresConfirmation(true));
          listenerApi.dispatch(setActive(false));
        }
      }catch(error){
        console.error("Se rechazo el cambio de metodo");
      }
    }
});

paymentMethodMiddleware.startListening({
    matcher: paymentMethodApi.endpoints.setPaymentMethodWithConfirmation.matchRejected,
    effect: (action, listenerApi) => {
      console.info({onSetPaymentMethodWithConfirmationRejected: action});
      console.info("Error al cambiar de metodo luego de la confirmacion");
      listenerApi.dispatch(setActive(true));
      listenerApi.dispatch(setLoadingState(false));
    }
});


paymentMethodMiddleware.startListening({
    actionCreator: loadPaymentMethod,
    effect: (action, listenerApi) => {
      console.info({onLoadPaymentMethod: action});
      let checkFormValid=false;
      if("fields" in action.payload){
        action.payload.fields.forEach(field => {
          if("value" in field){
            listenerApi.dispatch(setFieldValue({
              name: field.name, 
              value: field.value
            }));
          }
          if("name" in field){
            checkFormValid=true;
          }
        });
        if(checkFormValid){
          let passFormValidation = action.payload.fields.every((element)=>(!("name" in element) || element.is_valid==true));
          console.info({formValid:passFormValidation});
          listenerApi.dispatch(setFormValid(passFormValidation));
        }else{
          listenerApi.dispatch(setFormValid(true));
        }
      }
      if("payment_btn_text" in action.payload){
        listenerApi.dispatch(setPaymentBtnText(action.payload.payment_btn_text));
      }
    }
});

paymentMethodMiddleware.startListening({
    matcher: paymentMethodApi.endpoints.executePaymentMethod.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onSetExecutePaymentMethodFulfilled: action});
      listenerApi.dispatch(setLoadingTransactionState(false));
      if("update_fields" in action.payload){
        if(action.payload.update_fields){
          listenerApi.dispatch(setFields(action.payload.fields));
        }
      }
      if("payment_btn_text" in action.payload){
        listenerApi.dispatch(setPaymentBtnText(action.payload.payment_btn_text));
      }
      if("next_action" in action.payload){
        listenerApi.dispatch(setNextAction(action.payload.next_action));
        if(action.payload.next_action.type=="open_popup"){
          window.flex_pop_up_poqwieurndf30_ref=window.open(action.payload.next_action.data.url, '_blank', 'noopener,noreferrer');
          window.flex_pop_up_poqwieurndf30_state="open";
        }
        if(action.payload.next_action.type=="execute_authentication"){
          listenerApi.dispatch(setChargesResponse(action.payload.next_action.data));
        }
        if("state_loop_enabled" in action.payload.next_action){
          if(action.payload.next_action.state_loop_enabled){
            let operation = operationSlice.selectSlice(listenerApi.getState());
            let paymentMethod = paymentMethodSlice.selectSlice(listenerApi.getState());
            listenerApi.dispatch(setOperationConsult({operation, paymentMethod}));
          }
        }
      }
    }
});

paymentMethodMiddleware.startListening({
    matcher: paymentMethodApi.endpoints.consultPaymentStatus.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onConsultPaymentStatusFulfilled: action});
      let active = selectActive(listenerApi.getState());
      if(!active){
        return;
      }
      if("update_fields" in action.payload){
        if(action.payload.update_fields){
          if(window.flex_pop_up_poqwieurndf30_state=="open"){
            try{
              window.flex_pop_up_poqwieurndf30_ref.close();
            }catch(error){
              console.log("error on close");
            }
            window.flex_pop_up_poqwieurndf30_state="close";
          }
          listenerApi.dispatch(setFields(action.payload.fields));
          if("payment_btn_text" in action.payload){
            listenerApi.dispatch(setPaymentBtnText(action.payload.payment_btn_text));
          }
          if("next_action" in action.payload){
            listenerApi.dispatch(setNextAction(action.payload.next_action));
            return;
          }
        }else{
          let operation = operationSlice.selectSlice(listenerApi.getState());
          let paymentMethod = paymentMethodSlice.selectSlice(listenerApi.getState());
          setTimeout(()=>{
            listenerApi.dispatch(paymentMethodApi.endpoints.consultPaymentStatus.initiate({operation, paymentMethod}));
          }, 700);
        }
      }
    }
});

paymentMethodMiddleware.startListening({
    actionCreator: setNextAction,
    effect: (action, listenerApi) => {
      if("animation" in action.payload){
        if(!("withAnimation" in action.payload)){
          console.info({setNextActionNewAnimation: action});
          let payload = {...action.payload};
          payload.withAnimation=true;
          payload.animationCompleted=false;
          listenerApi.dispatch(setNextAction(payload));
          listenerApi.dispatch(setIsPlaying(true));
        }else if("withAnimation" in action.payload 
          && action.payload["animationCompleted"]){
          if("delay" in action.payload){
            let payload = {...action.payload};
            delete payload.animation;
            delete payload.withAnimation;
            delete payload.animationCompleted;
            listenerApi.dispatch(setNextAction(payload));
          }
        }
      }else if("delay" in action.payload){
        if(!("withDelay" in action.payload)){
          console.info({setNextActionDelay: action});
          let payload = {...action.payload};
          payload.withDelay=true;
          payload.delayCompleted=false;
          listenerApi.dispatch(setNextAction(payload));
          listenerApi.dispatch(setDelayTime(Number(payload.delay)));
        }
      }
    }
});

paymentMethodMiddleware.startListening({
    actionCreator: setFieldValue,
    effect: (action, listenerApi) => {
      let operation = operationSlice.selectSlice(listenerApi.getState());
      let paymentMethod = paymentMethodSlice.selectSlice(listenerApi.getState());
      let field = selectFieldByName(listenerApi.getState(), action.payload.name);
      let dependencies = ("dependencies" in field) ? field["dependencies"] : [];
      let dependentFields = [];
      for (let keydep in dependencies){
        dependentFields.push(selectFieldByName(listenerApi.getState(), dependencies[keydep]));
      }
      listenerApi.dispatch(validationApi.endpoints.validatePaymentMethodField.initiate({operation, paymentMethod, field, dependentFields}));
    }
});

paymentMethodMiddleware.startListening({
    matcher: validationApi.endpoints.validatePaymentMethodField.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onValidatePaymentMethodFieldFulfilled: action});
      listenerApi.dispatch(setFieldIsValid(action.payload.field));
      if(action.payload.field.name=="field_card"){
        let meta = "meta" in action.payload.field ? action.payload.field.meta : {};
        if(meta.installments!=undefined){
          if(meta.installments.new_bin){
            let selected_installment=selectFieldByName(listenerApi.getState(), "field_installments");
            if(selected_installment.value!="0"){
              listenerApi.dispatch(setFieldValue({name:"field_installments", value:"0"}));
            }
          }
        }
        let field_cvv = selectFieldByName(listenerApi.getState(), "field_cvv");
        if(field_cvv!=undefined && "value" in field_cvv){
          listenerApi.dispatch(setFieldValue({name:"field_cvv", value:field_cvv.value}));
        }
      }
    }
});

paymentMethodMiddleware.startListening({
    actionCreator: setFieldIsValid,
    effect: (action, listenerApi) => {
      console.info({onSetFieldIsValid: action});
      let fields = selectFields(listenerApi.getState());
      console.info({fields: fields});
      let passFormValidation = fields.every((element)=>(!("name" in element) || element.is_valid==true));
      console.info({formValid:passFormValidation});
      listenerApi.dispatch(setFormValid(passFormValidation));
    }
});