const constants = {}
export const URL_SERVICIO_FLEX_CAPTURE="URL_SERVICIO_FLEX_CAPTURE";
export const URL_SERVICIO_OAUTH="URL_SERVICIO_OAUTH";
export const HTTP_TEST_TYPE="HTTP_TEST_TYPE";
export const COMPILED_ENV="COMPILED_ENV";
export const URL_ASSETS="URL_ASSETS";
export const TOKEN_AUDIENCE="TOKEN_AUDIENCE";
constants[URL_SERVICIO_FLEX_CAPTURE]=__URL_SERVICIO_FLEX_CAPTURE__;
constants[URL_SERVICIO_OAUTH]=__URL_SERVICIO_OAUTH__;
constants[HTTP_TEST_TYPE]=__HTTP_TEST_TYPE__;
constants[COMPILED_ENV]=__COMPILED_ENV__;
constants[URL_ASSETS]=__URL_ASSETS__;
constants[TOKEN_AUDIENCE]=__TOKEN_AUDIENCE__;
export const setConstants = (some_constants) => {
    if(getConstant(COMPILED_ENV)==='production')
        return;
    for (var constant_key in some_constants) {
        constants[constant_key]=some_constants[constant_key];
    }
}
export const getConstant = (constant_key, default_value=null) => {
    return (constant_key in constants) ? constants[constant_key] : default_value;
}
export const getFlexCaptureURL = (default_value=null) => getConstant(URL_SERVICIO_FLEX_CAPTURE, default_value);
export const getOAuthServiceURL = (default_value=null) => getConstant(URL_SERVICIO_OAUTH, default_value);
export const getHTTPTestType = (default_value=null) => getConstant(HTTP_TEST_TYPE, default_value);
export const getUrlAssets = (default_value=null) => getConstant(URL_ASSETS, default_value);
export const getTokenAudience = (default_value=null) => getConstant(TOKEN_AUDIENCE, default_value);