import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFlexCaptureURL } from '../../constants';

export const sessionApi = createApi({
  reducerPath: 'sessionapi',
  baseQuery: fetchBaseQuery({ baseUrl: getFlexCaptureURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    createSession: builder.mutation({
      query: ({integration}) => ({
        url: '/session',
        method: 'POST',
        body:{
          integration
        }
      })
    })
  })
});

export const { useCreateSessionMutation } = sessionApi;