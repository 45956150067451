import { createSlice } from '@reduxjs/toolkit';

export const operationSlice = createSlice({
  name: 'operation',
  initialState: {
    id: null,
    loading: false
  },
  reducers: {
    setLoadingState: (state) => { state.loading=true },
    loadOperation: (state, action) => {
      state.id = action.payload.id;
      state.operation = action.payload.operation;
      state.loading = false;
    }
  },
  selectors: {
    selectId: (state) => state.id,
    selectLoading: (state) => state.loading,
    selectOperation: (state) => state.operation,
    selectError: (state) => state.error
  }
});

export const { setLoadingState, loadOperation } = operationSlice.actions;

export const { selectId, selectLoading, selectOperation, selectError } = operationSlice.selectors;

export default operationSlice.reducer;
