import { createSlice } from '@reduxjs/toolkit';

export const loopConsultPaymentSlice = createSlice({
  name: 'loopConsultPayment',
  initialState: {
    active: false,
    operationConsult: null
  },
  reducers: {
    setOperationConsult: (state, action) => { 
      console.log("setOpConsult");
      state.operationConsult=action.payload;
    },
    setActive: (state, action) => { 
      state.active=action.payload;
    },
  },
  selectors: {
    selectActive: (state) => state.active,
    selectOperationConsult: (state) => state.operationConsult,
  }
});

export const { setOperationConsult, setActive } = loopConsultPaymentSlice.actions;

export const { selectActive, selectOperationConsult } = loopConsultPaymentSlice.selectors;

export default loopConsultPaymentSlice.reducer;