import { createSlice } from '@reduxjs/toolkit';

export const delayClockSlice = createSlice({
  name: 'delayClock',
  initialState: {
    pristine: true,
    delay: 0,
    timeLeft: 0,
    isTicking: false
  },
  reducers: {
    setIsTicking: (state, action) => {
      state.isTicking=action.payload;
    },
    setDelay: (state, action) => {
      state.delay = action.payload;
      state.pristine=false;
    },
    setTimeLeft: (state, action) => {
      state.timeLeft = action.payload;
    },
  },
  selectors: {
    selectPristine: (state) => state.pristine,
    selectDelay: (state) => state.delay,
    selectTimeLeft: (state) => state.timeLeft,
    selectIsTicking: (state) => state.isTicking,
  }
});

export const { setIsTicking, setDelay, setTimeLeft } = delayClockSlice.actions;

export const { selectPristine, selectIsTicking, selectDelay, selectTimeLeft } = delayClockSlice.selectors;

export default delayClockSlice.reducer;