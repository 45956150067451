import { createListenerMiddleware } from '@reduxjs/toolkit';
import { operationSlice } from '../reducers/operation';
import { paymentMethodSlice } from '../reducers/paymentMethod';
import { authenticationApi } from '../services/authentication';
import { authenticationSlice, setLoadingState, setLoadingAuthorizationState, setConsultErrorCount, setChargesResponse, setAuthenticationResult, setChallengeURL, setRedisHash, setAcquirerMerchantKey, setTransactionId, setChallengeInProgress } from '../reducers/authentication';
import { sessionSlice } from '../reducers/session';
import { setOperationConsult } from '../reducers/loopConsultPayment';

export const authenticationMiddleware = createListenerMiddleware();

authenticationMiddleware.startListening({
    actionCreator: setChargesResponse,
    effect: (action, listenerApi) => {
      console.info({onSetChargesResponse: action});
      let transactionId=action.payload.transaction.transaction_id;
      listenerApi.dispatch(setTransactionId(transactionId));
      let operation = operationSlice.selectSlice(listenerApi.getState());
      listenerApi.dispatch(authenticationApi.endpoints.invokeAuthentication.initiate({operation, chargesResponse: action.payload, colorDepth:getColorDepth()}));
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.invokeAuthentication.matchPending,
    effect: (action, listenerApi) => {
      console.info({onInvokeAuthenticationPending: action});
      listenerApi.dispatch(setLoadingState(true));
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.invokeAuthentication.matchFulfilled,
    effect: (action, listenerApi) => {
      listenerApi.dispatch(setLoadingState(false));
      console.info({onInvokeAuthenticationFulfilled: action});
      try{
        if("error" in action.payload){
          console.log({authenticationFail:action.payload})
          let result = buildFailedResult("Error en respuesta al autenticar desde Flex. "+parseFulfilledDetail(action));
          listenerApi.dispatch(setAuthenticationResult(result));
          return;
        }
        let session = sessionSlice.selectSlice(listenerApi.getState());
        console.log({flexParams:session.flexParams})
        let auth_data=action.payload;
        let redis_hash = auth_data.redis_hash;
        let trans_status = auth_data.trans_status;
        let three_ds_trans_id = auth_data.three_ds_server_trans_id;
        let acs_trans_id = auth_data.acs_trans_id;
        let message_version = auth_data.message_version;
        let ds_trans_id = auth_data.ds_trans_id;
        let eci = auth_data.eci;
        let vci = auth_data.vci;
        let authentication_value = auth_data.authentication_value;
        let acquirerMerchantKey = auth_data.acquirerMerchantKey;
        let transactionId = auth_data.transaction_id;
        if (trans_status == undefined || trans_status == null || trans_status == "") {
            console.log({authenticationFail:action.payload})
            let result = buildFailedResult("Error en respuesta al autenticar desde Flex, trans_status nulo o vacio. "+parseFulfilledDetail(action), auth_data);
            listenerApi.dispatch(setAuthenticationResult(result));
            return;
        }
        if(trans_status=="C"){
          let urlServicioChallenge = buildURLWithParams(
              session.flexParams.url_challenge_authentication, {
                  "three_ds_trans_id": three_ds_trans_id,
                  "acs_trans_id": acs_trans_id,
                  "message_version": message_version,
                  "key": acquirerMerchantKey
              }
          );
          listenerApi.dispatch(setRedisHash(redis_hash));
          listenerApi.dispatch(setAcquirerMerchantKey(acquirerMerchantKey));
          listenerApi.dispatch(setChallengeURL(urlServicioChallenge));
        }else{
          console.log({authenticationComplete:action.payload})
          let result = buildSuccessfulResult("Autenticacion desde Flex sin friccion finalizada, trans_status: "+trans_status, auth_data);
          listenerApi.dispatch(setAuthenticationResult(result));
        }
      }catch(e){
        console.log({authenticationFail:action.payload})
        let result = buildFailedResult("Error no controlado al autenticar desde Flex. Exception:"+objToString(e)+". "+parseFulfilledDetail(action));
        listenerApi.dispatch(setAuthenticationResult(result));
      }
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.invokeAuthentication.matchRejected,
    effect: (action, listenerApi) => {
      console.info({onInvokeAuthenticationRejected: action});
      listenerApi.dispatch(setLoadingState(false));
      let result = buildFailedResult("Error al autenticar desde Flex. "+parseRejectDetail(action));
      listenerApi.dispatch(setAuthenticationResult(result));
    }
});

authenticationMiddleware.startListening({
    actionCreator: setAuthenticationResult,
    effect: (action, listenerApi) => {
      console.info({onSetAuthenticationResult: action});
      let formData = new URLSearchParams(action.payload);
      let session = sessionSlice.selectSlice(listenerApi.getState());
      let authentication = authenticationSlice.selectSlice(listenerApi.getState());
      listenerApi.dispatch(authenticationApi.endpoints.notifyAuthenticationRes.initiate({
          formData: formData,
          transactionId: authentication.transactionId,
          urlFinalizePayment: session.flexParams.url_finalize_payment
        }));
      listenerApi.dispatch(setLoadingAuthorizationState(true));
      let operation = operationSlice.selectSlice(listenerApi.getState());
      let paymentMethod = paymentMethodSlice.selectSlice(listenerApi.getState());
      listenerApi.dispatch(setOperationConsult({operation, paymentMethod}));
    }
});

authenticationMiddleware.startListening({
    actionCreator: setChallengeURL,
    effect: (action, listenerApi) => {
      console.info({onSetChallengeURL: action});
      let session = sessionSlice.selectSlice(listenerApi.getState());
      let authentication = authenticationSlice.selectSlice(listenerApi.getState());
      listenerApi.dispatch(authenticationApi.endpoints.consultAuthenticationStatus.initiate({
        redisHash: authentication.redisHash,
        acquirerMerchantKey: authentication.acquirerMerchantKey,
        urlStatusAuthentication: session.flexParams.url_status_authentication
      }));
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.consultAuthenticationStatus.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onConsultAuthenticationStatusFulfilled: action});
      listenerApi.dispatch(setConsultErrorCount(0));
      let auth_data=action.payload;
      let trans_status = auth_data.trans_status;
      let session = sessionSlice.selectSlice(listenerApi.getState());
      let authentication = authenticationSlice.selectSlice(listenerApi.getState());
      if(trans_status=="C"){
        setTimeout(()=>{
          listenerApi.dispatch(authenticationApi.endpoints.consultAuthenticationStatus.initiate({
            redisHash: authentication.redisHash,
            acquirerMerchantKey: authentication.acquirerMerchantKey,
            urlStatusAuthentication: session.flexParams.url_status_authentication
          }));
        }, 1000);
        return;
      }
      if(trans_status=="Y" || trans_status=="A"){
        listenerApi.dispatch(setChallengeInProgress(false));
        let result = buildSuccessfulResult("Paso autenticacion con fricion desde Flex. trans_status: " + trans_status, auth_data);
        listenerApi.dispatch(setAuthenticationResult(result));
        return;
      }
      if(trans_status=="N" || trans_status=="D" || trans_status=="R"){
        listenerApi.dispatch(setChallengeInProgress(false));
        let result = buildSuccessfulResult("Transaccion no paso autenticacion con fricion desde Flex. trans_status: " + trans_status, auth_data);
        listenerApi.dispatch(setAuthenticationResult(result));
        return;
      }else{
        listenerApi.dispatch(setChallengeInProgress(false));
        let result = buildSuccessfulResult("Transaccion no paso autenticacion con fricion desde Flex. No se reconoce trans_status: " + trans_status, auth_data);
        listenerApi.dispatch(setAuthenticationResult(result));
        return;
      }
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.consultAuthenticationStatus.matchRejected,
    effect: (action, listenerApi) => {
      console.info({onConsultAuthenticationStatusRejected: action});
      let authentication = authenticationSlice.selectSlice(listenerApi.getState());
      if(authentication.consultErrorCount >= 3){
        listenerApi.dispatch(setChallengeInProgress(false));
        let msg = "Error al autenticar desde Flex. Se dieron "+authentication.consultErrorCount+" errores al consultar status de autenticacion. ";
        console.log(msg);
        let result = buildFailedResult(msg+parseFulfilledDetail(action));
        listenerApi.dispatch(setAuthenticationResult(result));
        return;
      }
      listenerApi.dispatch(setConsultErrorCount(authentication.consultErrorCount+1));
    }
});

authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.notifyAuthenticationRes.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onNotifyAuthenticationResFulfilled: action});
      listenerApi.dispatch(setLoadingAuthorizationState(false));
    }
});
authenticationMiddleware.startListening({
    matcher: authenticationApi.endpoints.notifyAuthenticationRes.matchRejected,
    effect: (action, listenerApi) => {
      console.info({onNotifyAuthenticationResRejected: action});
      listenerApi.dispatch(setLoadingAuthorizationState(false));
    }
});

function parseRejectDetail(action){
  var msg="";
  try{
    msg += "http status:" + action.payload.status;
  }catch(e){
    msg += "http status:Undefined";
  }
  try{
    if("error" in action.payload.data){
      msg += "|error_msg:" + objToString(action.payload.data.error);
    }else if("message" in action.payload.data){
      msg += "|error_msg:" + objToString(action.payload.data.message);
    }else{
      msg += "|error_msg:Undefined"
    }
  }catch(e){
    msg += "|error_msg:Undefined";
  }
  try{
    msg += "|result:" + objToString(action.payload.data);
  }catch(e){
    msg += "|result:Undefined";
  }
  try{
    msg += "|url:" + action.meta.baseQueryMeta.request.url;
  }catch(e){
    msg += "|url:Undefined";
  }
  return msg;
}

function parseFulfilledDetail(action){
  var msg="";
  try{
    msg += "http status:" + action.meta.baseQueryMeta.response.status;
  }catch(e){
    msg += "http status:Undefined";
  }
  try{
    try{
      msg += "|error_msg:" + objToString(action.payload.error);
    }catch(e){
      msg += "|error_msg:" + objToString(action.payload.message);
    }
  }catch(e){
    msg += "|error_msg:Undefined";
  }
  try{
    msg += "|result:" + objToString(action.payload);
  }catch(e){
    msg += "|result:Undefined";
  }
  try{
    msg += "|url:" + action.meta.baseQueryMeta.request.url;
  }catch(e){
    msg += "|url:Undefined";
  }
  return msg;
}

function objToString(obj){
  if(typeof obj == "string")
    return obj;
  try{
    return JSON.stringify(obj);
  }catch(e){
    return ""+obj;
  }
}

function buildSuccessfulResult(statusMessage = "", authentication_result) {
    return {
        status_code: "00",
        status_message: statusMessage,
        authentication_result: objToString(authentication_result)
    };
}
function buildFailedResult(statusMessage = "", authentication_result=null) {
    let msg = {
        status_code: "01",
        status_message: statusMessage
    }
    if(authentication_result != null) {
        msg.authentication_result = objToString(authentication_result)
    }
    return msg;
}
        
function getColorDepth() {
    let listColorDepth = [48, 32, 24, 16, 15, 8, 4, 2, 1];
    let currentColorDepth = window.screen.colorDepth;
    let closestColorDepth = listColorDepth[0];
    for (let i = 1; i < listColorDepth.length; i++) {
        if (Math.abs(listColorDepth[i] - currentColorDepth) < Math.abs(closestColorDepth - currentColorDepth)) {
            closestColorDepth = listColorDepth[i];
        }
    }
    return closestColorDepth;
}

function buildURLWithParams(url, params) {
    let paramsList = [];
    for (let key in params) {
        let url_param = {}
        url_param[key] = params[key]
        paramsList.push(new URLSearchParams(url_param).toString());
    }
    let paramsString = paramsList.join("&");
    if (url.indexOf("?") > -1)
        return url + "&" + paramsString;
    else
        return url + "?" + paramsString;
}