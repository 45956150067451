import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFlexCaptureURL } from '../../constants';

export const operationApi = createApi({
  reducerPath: 'operationapi',
  baseQuery: fetchBaseQuery({ baseUrl: getFlexCaptureURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    createOperation: builder.mutation({
      query: (payload) => ({
        url: '/operation',
        method: 'POST',
        body: payload
      })
    })
  })
});

export const { useCreateOperationMutation } = operationApi;