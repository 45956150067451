import { createSlice } from '@reduxjs/toolkit';

export const paymentMethodChangeSlice = createSlice({
  name: 'paymentMethodChange',
  initialState: {
    pristine: true,
    operationChange: null,
    confirmedByUser: false,
    requiresConfirmation: false
  },
  reducers: {
    setOperationChange: (state, action) => { 
      try{
        console.log({actionOpChange:action})
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      console.log("Asdfasdfs");
      
      state.operationChange=action.payload; 
      state.pristine=false;
      state.confirmedByUser=false;
      state.requiresConfirmation=false;
      }catch(error){
        console.error("aaaaadfasdfasdfasdfad")
      }
    },
    setConfirmedByUser: (state, action) => { 
      state.confirmedByUser=action.payload;
    },
    setRequiresConfirmation: (state, action) => { 
      state.requiresConfirmation=action.payload;
    },
  },
  selectors: {
    selectPristine: (state) => state.pristine,
    selectOperationChange: (state) => state.operationChange,
    selectConfirmedByUser: (state) => state.confirmedByUser,
    selectRequiresConfirmation: (state) => state.requiresConfirmation
  }
});

export const { setOperationChange, setConfirmedByUser, setRequiresConfirmation } = paymentMethodChangeSlice.actions;

export const { selectPristine, selectOperationChange, selectConfirmedByUser, selectRequiresConfirmation } = paymentMethodChangeSlice.selectors;

export default paymentMethodChangeSlice.reducer;