import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    id: null,
    loading: false
  },
  reducers: {
    setLoadingState: (state) => { state.loading=true },
    loadSession: (state, action) => {
      state.id = action.payload;
      state.loading = false;
    },
    loadFlexParams: (state, action) => { state.flexParams=action.payload },
  },
  selectors: {
    selectId: (state) => state.id,
    selectLoading: (state) => state.loading,
    selectFlexParams: (state) => state.flexParams,
    selectError: (state) => state.error
  }
});

export const { setLoadingState, loadSession, loadFlexParams } = sessionSlice.actions;

export const { selectId, selectLoading, selectError, selectFlexParams } = sessionSlice.selectors;

export default sessionSlice.reducer;
