import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import IframeResizer from 'iframe-resizer-react';

export default function ModalIframe({ url }) {
  const [showModal, setShowModal] = useState(false);
  const onWaitComplete = () => {
    console.log("SHOW ModalIframe");
    setShowModal(true);
  };
  useEffect(() => {
    console.log("INIT ModalIframe");
    const tout = setTimeout(()=>{onWaitComplete();}, 100);
    return () => {
      console.log("DELETING ModalIframe");
      console.log({ "clearing": tout });
      clearTimeout(tout);
    };
  }, []);
  if(showModal){
    return (
      <>
        <Modal isOpen={true}  contentLabel="" overlayClassName={"modal-overlay-iframe-flex"} className={"modal-iframe-flex"}>
          <IframeResizer log src={url} style={{ width: '1px', minWidth: '100%', border: 0, minHeight:'100%'}} scrolling={true}/>
        </Modal>
      </>
      );
  }else{
    return (<></>);
  }
    
}