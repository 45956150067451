import { useEffect } from 'react';

export default function DelayClock({onEndOfDelay, delay, onStartOfDelay, onDelayTick}){
  const onTick = (count) => {
    if(count.completed){
      console.log("delay Completed");
      return;
    }
    count.value--;
    console.log("delay Tick "+count.value);
    onDelayTick(count.value);
    if(count.value<=0){
      onEndOfDelay();
      count.completed=true;
    }
  };
  useEffect(() => {
    if(isNaN(delay) || delay==undefined || delay==null || delay==0){
      return () => {};
    }else{
      const count = {value:1+parseInt(delay, 10), completed:false};
      onStartOfDelay();
      onTick(count);
      const tout = setInterval(()=>{onTick(count)}, 1000);
      return () => {
        console.log({"clearing delay":tout});
        clearTimeout(tout);
      };
    }
      
  }, [delay]);
  return (
    <>
    </>
  );
}