import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import '../css/layout.styl'
import PaymentMethod from './PaymentMethod'
import { getUrlAssets } from '../constants'
import PaymentMethodConfirmation from '../components/PaymentMethodConfirmation'
import SuccessAnimation from '../components/SuccessAnimation'
import Modal from 'react-modal';
import { selectLoading as selectAuthLoading } from '../app/reducers/auth'
import { selectLoading as selectSessionLoading } from '../app/reducers/session'
import { selectLoading as selectOperationLoading, operationSlice } from '../app/reducers/operation'
import { selectLoading as selectPaymentMethodLoading, selectLoadingTransaction, 
  selectId as selectPaymentMethodId
} from '../app/reducers/paymentMethod'
import { selectList } from '../app/reducers/methodsList'
import { setMockResponse, selectFirstView, selectMockResponse } from '../app/reducers/paymentMethod'
import { selectLoading as selectAuthenticationLoading, selectLoadingAuthorization } from '../app/reducers/authentication'
import { selectSettings } from '../app/reducers/flexConfiguration';
import { setOperationChange } from '../app/reducers/paymentMethodChange';
export default function PaymentUI({responseCallback, trackingCallback, onErrorCallback}){
  const [resolution, setResolution] = useState('sm');
  const [collapsed, setCollapsed] = useState(true);
  const containerRef = useRef(null);
  const modalContainerRef = useRef(null);
  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let w = entry.contentRect.width;
        if(w>=1536){
          setResolution('2xl');
        }else if(w>=1280){
          setResolution('xl');
        }else if(w>=1024){
          setResolution('lg');
        }else if(w>=768){
          setResolution('md');
        }else if(w>=640){
          setResolution('sm');
        }else{
          setResolution('xs');
        }
      }
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);
  const dispatch = useDispatch();
  const authLoading = useSelector(selectAuthLoading);
  const sessionLoading = useSelector(selectSessionLoading);
  const operationLoading = useSelector(selectOperationLoading);
  const paymentMethodLoading = useSelector(selectPaymentMethodLoading);
  const transactionLoading = useSelector(selectLoadingTransaction);
  const authenticationLoading = useSelector(selectAuthenticationLoading);
  const authorizationLoading = useSelector(selectLoadingAuthorization);
  let isLoading = authLoading ||
                    sessionLoading ||
                    operationLoading ||
                    paymentMethodLoading ||
                    transactionLoading ||
                    authenticationLoading ||
                    authorizationLoading;
  const getLoadingMessage = () =>
    authLoading ? "Cargando Token" :
      sessionLoading ? "Creando Session" : 
        operationLoading ? "Creando Operacion" : 
          paymentMethodLoading ? "Cargando Metodo de Pago" :
            transactionLoading ? "Procesando Transaccion" :
              authenticationLoading ? "Procesando Autenticacion" :
                authorizationLoading ? "Procesando Autorizacion" :
                  "...";
  const methodsList = useSelector(selectList);
  const methodId = useSelector(selectPaymentMethodId);
  const chooseMethod = (method, operation) => {
    setCollapsed(true);
    console.log({methodChoosed:method, operation:operation});
    dispatch(setOperationChange({
      operation: operation,
      paymentMethod: method
    }));
  };
  const isFirstView = useSelector(selectFirstView);
  const mockResponse = useSelector(selectMockResponse);
  const configSettings = useSelector(selectSettings);
  const chooseResponse = (e) => {
    let value = e.target.value;
    dispatch(setMockResponse(value));
  };
  return(
    <div className={"payment-gateway-ui"} ref={containerRef}>
      <div className={"payment-gateway-modal-container"} ref={modalContainerRef}></div>
      {false && <Modal
        isOpen={!isLoading}
        contentLabel="Example Modal"
        parentSelector={() => modalContainerRef.current}
      >
        <div>I am a modal</div>
      </Modal>}
      <div className={"loading-overlay "+(isLoading ? 'is-loading' : 'is-not-loading')}>
        <div className={"loading-detail"}>
          <div className={"loading-icon"}><div className={"spinner"}></div></div>
          <div className={"loading-message"}>{ isLoading ? getLoadingMessage() : "cargando..." }</div>
        </div>
      </div>
      <PaymentMethodConfirmation />
      <SuccessAnimation />
      <div className={"payment-gateway-container payui-resolution-"+resolution+(collapsed?"":" expanded")} >
        <div className={"select-payment-message"}>
          { (methodsList.length>1) ? "Selecciona el medio de Pago" : "" }
          { false && isFirstView && " | Selecciona Respuesta: " }
          { false && isFirstView && <select name="selectedResponse" 
              value={mockResponse}
              onChange={chooseResponse}>
              <option value="procesadora">Procesamiento Normal</option>
              <option value="autorizada">Autorizado</option>
              <option value="denegada">Denegado</option>
              <option value="challengeok">Inicia Challenge y Pasa</option>
              <option value="challengefail">Inicia Challenge y Falla</option>
              <option value="qrgenerado">Genera QR y Autoriza</option>
              <option value="cuotealook">Inicia Cuotealo y Autoriza</option>
              <option value="cuotealofail">Inicia Cuotealo y Falla</option>
              <option value="codigocipgenerado">Genera Codigo CIP</option>
              <option value="prometeook">Inicia Prometeo y Autoriza</option>
              <option value="prometeofail">Inicia Prometeo y Falla</option>
            </select>  }
          {configSettings.show_close_button && <div className={"back-to-merchant"} onClick={()=>{onErrorCallback({"message":"Cerro el carrito"})}}>Salir</div>}
        </div>
        <div className={"payment-methods-container"}>
          {methodsList.map((value,pos) => (<PaymentMethod key={pos} pos={pos} method={value} chooseMethod={chooseMethod} methodChoosed={value.id==methodId}  responseCallback={responseCallback} trackingCallback={trackingCallback} onErrorCallback={onErrorCallback} />))}
          {resolution=="xs" && methodsList.length > 1 && 
            <div className={"payment-method-wrapper collapse-btn"}>
              <div className={"payment-choose-btn choose-btn-"+(1+methodsList.length)} onClick={()=>{setCollapsed(!collapsed)}}>
                <img src={getUrlAssets()+(collapsed?"/sandwich.png":"/close.png")}
                  className={"sm-nav-payment-method-icon"}/>
              </div>
            </div>}
        </div>
      </div>
    </div>
  );
}
