import { useSelector, useDispatch } from 'react-redux';
import { selectPristine, selectIsPlaying, setIsPlaying } from '../app/reducers/successAnimation'
import PayMeAnimation from './PayMeAnimation'
import MastercardAnimation from './MastercardAnimation'
import VisaAnimation from './VisaAnimation'
import { selectNextAction, setNextAction } from '../app/reducers/paymentMethod';

export default function SuccessAnimation(){
  const dispatch = useDispatch();
  const isPlaying = useSelector(selectIsPlaying);
  const pristine = useSelector(selectPristine);
  const nextAction = useSelector(selectNextAction);
  const onCompleteAnimation = ()=>{
    console.log("animation completed..........");
    let payload={...nextAction};
    payload.animationCompleted=true;
    dispatch(setNextAction(payload));
    dispatch(setIsPlaying(false));
  };
  return (
    <div className={"loading-overlay "+(pristine ? 'pristine' : '')+(isPlaying ? ' is-loading' : ' is-not-loading')}>
      {!pristine && isPlaying && <PayMeAnimation onCompleteAnimation={onCompleteAnimation} />}
      {false && !pristine && isPlaying && <MastercardAnimation onCompleteAnimation={onCompleteAnimation} />}
      {false && !pristine && isPlaying && <VisaAnimation onCompleteAnimation={onCompleteAnimation} />}
    </div>
  );
}