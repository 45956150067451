import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUrlAssets } from '../constants'
// import paymeVideo from '../animations/Payme.mp4'

export default function PayMeAnimation({onCompleteAnimation}){
  const dispatch = useDispatch();
  const ref = useRef(null);
  useEffect(() => {
    const tout = setTimeout(()=>{ onCompleteAnimation(); }, 10000);
    return () => {
      console.log({"clearing":tout});
      clearTimeout(tout);
    };
  }, []);
  return (
    <div className={"loading-detail loading-video"}>
      <div className={"modal-title"}>
        <video ref={ref} src={getUrlAssets()+"/Payme.e4473e90.mp4"} onEnded={()=>{onCompleteAnimation()}} autoPlay playsInline />
      </div>
    </div>
  );
}