import { createSlice } from '@reduxjs/toolkit';

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    loading: false,
    loadingAuthorization: false,
    challengeInProgress: false,
    challengeURL: "",
    authenticationResult: null,
    consultErrorCount: 0
  },
  reducers: {
    setLoadingState: (state, action) => { state.loading=action.payload },
    setLoadingAuthorizationState: (state, action) => { state.loadingAuthorization=action.payload },
    setChargesResponse: (state, action) => { state.chargesResponse=action.payload },
    setChallengeURL: (state, action) => { 
      state.challengeURL=action.payload; 
      state.challengeInProgress=true;
    },
    setRedisHash: (state, action) => { state.redisHash=action.payload },
    setAcquirerMerchantKey: (state, action) => { state.acquirerMerchantKey=action.payload },
    setTransactionId: (state, action) => { state.transactionId=action.payload },
    setChallengeInProgress: (state, action) => { state.challengeInProgress=action.payload },
    setAuthenticationResult: (state, action) => { state.authenticationResult=action.payload },
    setConsultErrorCount: (state, action) => { state.consultErrorCount=action.payload },
  },
  selectors: {
    selectLoading: (state) => state.loading,
    selectLoadingAuthorization: (state) => state.loadingAuthorization,
    selectChargesResponse: (state) => state.chargesResponse,
    selectChallengeInProgress: (state) => state.challengeInProgress,
    selectChallengeURL: (state) => state.challengeURL,
    selectAuthenticationResult: (state) => state.authenticationResult,
    selectError: (state) => state.error
  }
});

export const { setLoadingState, setLoadingAuthorizationState, setAuthenticationResult, setConsultErrorCount, setChargesResponse, setChallengeURL, setChallengeInProgress, setRedisHash, setAcquirerMerchantKey, setTransactionId } = authenticationSlice.actions;

export const { selectLoading, selectChargesResponse, selectAuthenticationResult, selectError, selectChallengeURL, selectChallengeInProgress, selectLoadingAuthorization } = authenticationSlice.selectors;

export default authenticationSlice.reducer;
