import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFlexCaptureURL } from '../../constants';

export const authenticationApi = createApi({
  reducerPath: 'authenticationapi',
  baseQuery: fetchBaseQuery({ baseUrl: getFlexCaptureURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    invokeAuthentication: builder.mutation({
      query: ({operation, chargesResponse, colorDepth}) => ({
        url: `/operation/${operation.id}/authentication`,
        method: 'POST',
        body: {
          operation,
          charges_response: chargesResponse,
          browserColorDepth: colorDepth,
          browserScreenHeight: window.screen.height,
          browserScreenWidth: window.screen.width
        }
      })
    }),
    consultAuthenticationStatus: builder.mutation({
      query: ({redisHash, acquirerMerchantKey, urlStatusAuthentication}) => ({
        url: urlStatusAuthentication,
        method: 'POST',
        body: {
          three_ds_server_trans_id: redisHash
        },
        headers: {
          key: acquirerMerchantKey
        }
      })
    }),
    notifyAuthenticationRes: builder.mutation({
      query: ({formData, transactionId, urlFinalizePayment}) => ({
        url: urlFinalizePayment+"card/"+transactionId,
        method: 'POST',
        body: formData
      })
    })
  })
});

export const { 
  useInvokeAuthenticationMutation, 
  useConsultAuthenticationStatusMutation,
  useNotifyAuthenticationResMutation
} = authenticationApi;