import { createSlice } from '@reduxjs/toolkit';

export const flexConfigurationSlice = createSlice({
  name: 'flexConfiguration',
  initialState: {},
  reducers: {
    loadConfiguration: (state, action) => {
      return {...state, ...action.payload};
    }
  },
  selectors: {
    selectPayload: (state) => state.payload,
    selectSettings: (state) => state.settings,
    selectDisplaySettings: (state) => state.display_settings
  }
});

export const { loadConfiguration } = flexConfigurationSlice.actions;
console.log({paaa:flexConfigurationSlice.reducerPath});
export const { selectPayload, selectSettings, selectDisplaySettings } = flexConfigurationSlice.getSelectors(
  (state) => state[flexConfigurationSlice.reducerPath]
);

export default flexConfigurationSlice.reducer;
