import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFlexCaptureURL } from '../../constants';

export const validationApi = createApi({
  reducerPath: 'validationdapi',
  baseQuery: fetchBaseQuery({ baseUrl: getFlexCaptureURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    validatePaymentMethodField: builder.mutation({
      query: ({operation, paymentMethod, field, dependentFields}) => ({
        url: `/operation/${operation.id}/validation`,
        method: 'POST',
        body: {
          operation,
          payment_method: paymentMethod,
          with_installments: true,
          field,
          dependentFields
        }
      })
    })
  })
});

export const { useSetPaymentMethodMutation, useValidatePaymentMethodFieldMutation } = validationApi;