import { createSlice } from '@reduxjs/toolkit';

export const methodsListSlice = createSlice({
  name: 'methodsList',
  initialState: {
    loading: false,
    list: []
  },
  reducers: {
    setLoadingState: (state) => { state.loading=true },
    loadList: (state, action) => {
      state.list = action.payload;
      state.loading = false;
    }
  },
  selectors: {
    selectList: (state) => state.list,
    selectLoading: (state) => state.loading,
    selectError: (state) => state.error
  }
});

export const { setLoadingState, loadList } = methodsListSlice.actions;

export const { selectList, selectLoading, selectError } = methodsListSlice.selectors;

export default methodsListSlice.reducer;
