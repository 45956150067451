import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore } from './app/store';
import { authApi } from './app/services/auth';
import { loadConfiguration } from './app/reducers/flexConfiguration';
import PaymentUI from './containers/PaymentUI';
import { setConstants } from './constants';
window.React = React;
class FlexPaymentForms {
  constructor(config) {
    console.log({ flexCaptureConfig: config });
    let default_settings={
      show_close_button: true
    };
    if("settings" in config){
      config.settings={...default_settings, ...config.settings};
    }else{
      config.settings={...default_settings};
    }
    if ("constants" in config) {
      setConstants(config["constants"]);
    }
    console.log({ flexCaptureConfigAfter: config });
    const self = this;
    this.init = function(){
      console.info({ initArguments: arguments });
      let domElement = arguments[0];
      let responseCallback = (arguments.length>1) ? arguments[1] : ()=>{};
      let trackingCallback = (arguments.length>2) ? arguments[2] : ()=>{};
      let onErrorCallback = (arguments.length>3) ? arguments[3] : ()=>{};
      console.info({ domElement: domElement });
      self.store = createStore();
      console.info("loadConfiguration");
      self.store.dispatch(loadConfiguration(JSON.parse(JSON.stringify(config))));
      console.info("init createToken");
      self.store.dispatch(authApi.endpoints.createToken.initiate(config));
      self.reactRoot = createRoot(domElement);
      self.reactRoot.render(
        <StrictMode>
          <Provider store={self.store}>
            <PaymentUI responseCallback={responseCallback} trackingCallback={trackingCallback} onErrorCallback={onErrorCallback} />
          </Provider>
        </StrictMode>
      );
    };
    this.terminate = function() {
      console.log("react terminate");
      self.reactRoot.unmount();
    };
  }
}

export default FlexPaymentForms;