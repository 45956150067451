import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getOAuthServiceURL, getTokenAudience } from '../../constants'

// Define our single API slice object
export const authApi = createApi({
  reducerPath: 'authapi',
  baseQuery: fetchBaseQuery({ baseUrl: getOAuthServiceURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    createToken: builder.mutation({
      query: (flexCaptureConfig) => ({
        url: '/token',
        method: 'POST',
        body: buildTokenRequestBody(flexCaptureConfig)
      })
    })
  })
})

function buildTokenRequestBody(flexCaptureConfig){
  const body = {
    "action":"authorize",
    "audience":getTokenAudience(),
    "scope":"create:token post:charges"
  }
  if( "nonce" in flexCaptureConfig ){
    body["grant_type"] = "nonce";
    body["nonce"] = flexCaptureConfig["nonce"];
  }else if( "key" in flexCaptureConfig ){
    body["grant_type"] = "authorization_code"
    body["authorization_code"] = flexCaptureConfig["key"];
  }
  console.info({buildTokenRequestBody: body})
  return body;
}


export const { useCreateTokenMutation } = authApi