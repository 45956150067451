import { createListenerMiddleware } from '@reduxjs/toolkit';
import { paymentMethodApi } from '../services/paymentMethod';
import { setActive } from '../reducers/loopConsultPayment';
import { selectOperationChange, setOperationChange, setRequiresConfirmation, setConfirmedByUser } from '../reducers/paymentMethodChange';

export const paymentMethodChangeMiddleware = createListenerMiddleware();

paymentMethodChangeMiddleware.startListening({
    actionCreator: setOperationChange,
    effect: (action, listenerApi) => {
      console.info({onPaymentMethodChange: action});
      listenerApi.dispatch(paymentMethodApi.endpoints.setPaymentMethod.initiate({
        operation:action.payload.operation, 
        paymentMethod:action.payload.paymentMethod
      }));
    }
});

paymentMethodChangeMiddleware.startListening({
    actionCreator: setConfirmedByUser,
    effect: (action, listenerApi) => {
      console.info({onConfirmedByUser: action});
      const operationChange=selectOperationChange(listenerApi.getState());
      listenerApi.dispatch(setRequiresConfirmation(false));
      if(action.payload=="OK"){
        listenerApi.dispatch(setActive(false));
        listenerApi.dispatch(paymentMethodApi.endpoints.setPaymentMethodWithConfirmation.initiate({
          operation:operationChange.operation, 
          paymentMethod:operationChange.paymentMethod,
          confirmation:action.payload
        }));
      }else{
        listenerApi.dispatch(setActive(true));
      }
        
    }
});