import { createSlice } from '@reduxjs/toolkit';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    list: []
  },
  reducers: {
    loadIntegrations: (state, action) => {
      state.list = action.payload;
    }
  },
  selectors: {
    selectIntegrations: (state) => state.list
  }
});

export const { loadIntegrations } = integrationsSlice.actions;

export const { selectIntegrations } = integrationsSlice.selectors;

export default integrationsSlice.reducer;
