import { createListenerMiddleware } from '@reduxjs/toolkit';
import { paymentMethodApi } from '../services/paymentMethod';
import { operationSlice } from '../reducers/operation';
import { loadList } from '../reducers/methodsList';

export const methodsListMiddleware = createListenerMiddleware();

methodsListMiddleware.startListening({
    actionCreator: loadList,
    effect: (action, listenerApi) => {
      console.info({onLoadMethodsList: action});
      console.info({operationSlice: operationSlice.selectSlice(listenerApi.getState())});
      console.info({method: action.payload[0]});
      listenerApi.dispatch(
        paymentMethodApi.endpoints.setPaymentMethod.initiate({
            operation: operationSlice.selectSlice(listenerApi.getState()), 
            paymentMethod: action.payload[0]
          })
        );
      // listenerApi.dispatch(loadOperation(action.payload));
      // listenerApi.dispatch(loadList(action.payload.payment_methods));
    }
});