import { createListenerMiddleware } from '@reduxjs/toolkit';
import { authApi } from '../services/auth';
import { sessionApi } from '../services/session';
import { setLoadingState, loadToken } from '../reducers/auth';

export const authMiddleware = createListenerMiddleware();

authMiddleware.startListening({
    matcher: authApi.endpoints.createToken.matchPending,
    effect: (action, listenerApi) => {
      console.info({onCreateTokenPending: action});
      listenerApi.dispatch(setLoadingState());
    }
});

authMiddleware.startListening({
    matcher: authApi.endpoints.createToken.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onCreateTokenFulfilled: action});
      listenerApi.dispatch(loadToken(action.payload.access_token));
    }
});

authMiddleware.startListening({
    actionCreator: loadToken,
    effect: (action, listenerApi) => {
      console.info({onLoadToken: action});
      let integration={
        "type": "FLEX",
        "type_version": "2.0",
        "device_info": {
          "platform": window.navigator.platform,                        // Win32, Linux x86_64, MacIntel, etc.
          "user_agent": window.navigator.userAgent,                    // User agent completo del navegador
          "language": window.navigator.language || window.navigator.userLanguage, // Idioma configurado en el navegador
          "screen_resolution": `${window.screen.width}x${window.screen.height}`, // Resolución de pantalla
          "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone, // Zona horaria (por ejemplo, "America/Lima")
          "cookies_enabled": window.navigator.cookieEnabled
        }
      };
      listenerApi.dispatch(sessionApi.endpoints.createSession.initiate({integration}));
    }
});