import { createListenerMiddleware } from '@reduxjs/toolkit';
import { operationApi } from '../services/operation';
import { sessionApi } from '../services/session';
import { setLoadingState, loadSession, loadFlexParams } from '../reducers/session';
import { loadIntegrations } from '../reducers/integrations';
import { selectPayload } from '../reducers/flexConfiguration'

export const sessionMiddleware = createListenerMiddleware();

sessionMiddleware.startListening({
    matcher: sessionApi.endpoints.createSession.matchPending,
    effect: (action, listenerApi) => {
      console.info({onCreateSessionPending: action});
      listenerApi.dispatch(setLoadingState());
    }
});

sessionMiddleware.startListening({
    matcher: sessionApi.endpoints.createSession.matchFulfilled,
    effect: (action, listenerApi) => {
      console.info({onCreateSessionFulfilled: action});
      listenerApi.dispatch(loadSession(action.payload.id));
      listenerApi.dispatch(loadFlexParams(action.payload.flex_params));
      listenerApi.dispatch(loadIntegrations(action.payload.integrations));
    }
});

sessionMiddleware.startListening({
    actionCreator: loadSession,
    effect: (action, listenerApi) => {
      console.info({onLoadSession: action});
      listenerApi.dispatch(
        operationApi.endpoints.createOperation.initiate(
            selectPayload(listenerApi.getState())
          )
        );
    }
});