import  { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFlexCaptureURL } from '../../constants';

export const paymentMethodApi = createApi({
  reducerPath: 'paymentmethodapi',
  baseQuery: fetchBaseQuery({ baseUrl: getFlexCaptureURL() }),
  // The "endpoints" represent operations and requests for this server
  endpoints: builder => ({
    setPaymentMethod: builder.mutation({
      query: ({operation, paymentMethod}) => ({
        url: `/operation/${operation.id}/payment-method`,
        method: 'PUT',
        body: {
          operation,
          payment_method: paymentMethod,
          with_installments: true
        }
      })
    }),
    setPaymentMethodWithConfirmation: builder.mutation({
      query: ({operation, paymentMethod, confirmation}) => ({
        url: `/operation/${operation.id}/payment-method`,
        method: 'PUT',
        body: {
          operation,
          payment_method: paymentMethod,
          confirm_method_change: confirmation,
          with_installments: true
        }
      })
    }),
    executePaymentMethod: builder.mutation({
      query: ({operation, paymentMethod, integrations}) => ({
        url: `/operation/${operation.id}/execution`,
        method: 'POST',
        body: {
          operation,
          payment_method: paymentMethod,
          integrations,
          authentication_only_challenge: true
        }
      })
    }),
    consultPaymentStatus: builder.mutation({
      query: ({operation, paymentMethod}) => ({
        url: `/operation/${operation.id}/consult`,
        method: 'POST',
        body: {
          operation,
          payment_method: paymentMethod,
        }
      })
    })
  })
});

export const { useSetPaymentMethodMutation, useExecutePaymentMethodMutation } = paymentMethodApi;